import { getInstance } from "./authWrapper";
import AuthBehavior from "@/mixins/AuthBehavior";
import store from "@/store"; // Import the Vuex store

export const authGuardWithPermission = (requiredPermission) => async (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    if (authService.isAuthenticated) {
      const context = { $store: store }; // Create a context with the Vuex store
      if (await AuthBehavior.methods.hasPermission(context, requiredPermission)) {
        return next();
      } else {
        return next({ name: 'access-denied' });
      }
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};