async function fetchAuthUser(context) {
  if (!context.$store.state.patients.authUser || !context.$store.state.patients.authUser.behaviors) {
    await context.$store.dispatch('getAuthUser');
  }
  return context.$store.state.patients.authUser;
}

async function hasPermission(context, permission) {
  const authUser = await fetchAuthUser(context);
  return authUser && authUser.behaviors && authUser.behaviors.includes(permission);
}

export default {
  methods: {
    hasPermission,
  },
};