import Vue from 'vue';
import Router from 'vue-router';
import { authGuard } from '../auth';
import {authGuardWithPermission} from "@/auth/authGuardWithPermission";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/access-denied',
      name: 'access-denied',
      component: () => import('@/views/AccessDenied.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Access.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access',
      name: 'access',
      component: () => import('@/views/Access.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/verify-email',
      name: 'verify-email',
      component: () => import('@/views/VerifyEmail.vue')
    },

    {
      path: '/webinar-account-confirmation',
      name: 'webinar-account-confirmation',
      component: () => import('@/views/WebinarAccountConfirmation.vue')
    },

    {
      path: '/account-confirmed',
      redirect: to => {
        return { path: '/', query: { q: to.query } }
      }
    },

    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/Calendar.vue'),
      beforeEnter: authGuard
    },

    // {
    //   path: '/calculator',
    //   name: 'calculator',
    //   component: () => import('@/views/Calculator.vue')
    // },

    {
      path: '/calculator-15',
      name: 'calculator-15',
      component: () => import('@/views/Calculator15.vue')
    },

    {
      path: '/calculator-40',
      name: 'calculator-40',
      component: () => import('@/views/Calculator40.vue')
    },

    {
      path: '/jobs',
      name: 'jobs',
      component: () => import('@/views/JobBoard.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/memberships',
      name: 'memberships',
      component: () => import('@/views/Memberships.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/sms-min',
      name: 'sms-min',
      component: () => import('@/views/SmsMin.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/physician-vault',
      name: 'physical-vault',
      component: () => import('@/views/PhysicalVault.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/doctors-lounge',
      name: 'doctors-lounge',
      component: () => import('@/views/DoctorLounge.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/telehealth-education',
      name: 'telehealth-education',
      component: () => import('@/views/TeleHealthEducation.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/telehealth-education/webinars',
      name: 'webinars',
      component: () => import('@/views/Webinars.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/accredited-cme',
      name: 'accredited-cme',
      component: () => import('@/views/AccreditedCME.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/teleboard',
      name: 'teleboard',
      component: () => import('@/views/Teleboard.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/telehealth-ehr',
      name: 'telehealth-ehr',
      component: () => import('@/views/TelehealthEHR.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/provider-legal',
      name: 'provider-legal',
      component: () => import('@/views/ProviderLegal.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/credentialing',
      name: 'credentialing',
      component: () => import('@/views/Credentialing.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/access/rpm',
      name: 'rpm',
      component: () => import('@/views/RPM.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/rpm-calculator',
      name: 'rpm-calculator',
      component: () => import('@/views/RpmCalculator.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/rpm-dashboard',
      name: 'rpm-dashboard',
      redirect: { name: 'rpm-dashboard-patients' }
    },

    {
      path: '/rpm-dashboard/patient-report',
      name: 'rpm-dashboard-patient-report',
      component: () => import('@/views/RpmPatientReport.vue'),
      beforeEnter: authGuard
    },

    {
      path: '/rpm-dashboard/patient',
      name: 'rpm-dashboard-patients',
      component: () => import('@/views/rpm/Patients.vue'),
      beforeEnter: authGuardWithPermission('access_page_patients')
    },

    {
      path: '/rpm-dashboard/order-forms',
      name: 'rpm-dashboard-order-forms',
      component: () => import('@/views/rpm/OrderForms.vue'),
      beforeEnter: authGuardWithPermission('access_page_order_forms')
    },

    {
      path: '/rpm-dashboard/tasks',
      name: 'rpm-dashboard-tasks',
      component: () => import('@/views/rpm/Tasks.vue'),
      beforeEnter: authGuardWithPermission('access_page_your_tasks')
    },

    {
      path: '/rpm-dashboard/alert',
      name: 'rpm-dashboard-alerts',
      component: () => import('@/views/rpm/Alerts.vue'),
      beforeEnter: authGuardWithPermission('access_page_alerts')
    },

    {
      path: '/rpm-dashboard/rpm-data',
      name: 'rpm-dashboard-rpm-data',
      component: () => import('@/views/rpm/RpmData.vue'),
      beforeEnter: authGuardWithPermission('access_page_rpm_data')
    },

    {
      path: '/rpm-dashboard/ccm-data',
      name: 'rpm-dashboard-ccm-data',
      component: () => import('@/views/rpm/CcmData.vue'),
      beforeEnter: authGuardWithPermission('access_page_ccm_data')
    },

    {
      path: '/rpm-dashboard/billing-summary',
      name: 'rpm-dashboard-billing-summary',
      component: () => import('@/views/rpm/BillingSummary.vue'),
      beforeEnter: authGuardWithPermission('access_page_billing')
    },

    {
      path: '/rpm-dashboard/reports',
      name: 'rpm-dashboard-reports',
      component: () => import('@/views/rpm/Reports.vue'),
      beforeEnter: authGuardWithPermission('access_page_reports')
    },

    {
      path: '/rpm-dashboard/reports/monthly-report',
      name: 'rpm-dashboard-monthly-report',
      component: () => import('@/views/rpm/MonthlyReport.vue'),
      beforeEnter: authGuardWithPermission('access_page_reports')
    },

    {
      path: '/rpm-dashboard/reports/status-report',
      name: 'rpm-dashboard-status-report',
      component: () => import('@/views/rpm/StatusReport.vue'),
      beforeEnter: authGuardWithPermission('access_page_reports')
    },

    {
      path: '/rpm-dashboard/reports/task-report',
      name: 'rpm-dashboard-task-report',
      component: () => import('@/views/rpm/TaskReport.vue'),
      beforeEnter: authGuardWithPermission('access_page_reports')
    },

    {
      path: '/rpm-dashboard/patient-information/:id',
      name: 'rpm-dashboard-patient-information',
      component: () => import('@/views/rpm/patient-info/PatientInfo.vue'),
      props: true,
      beforeEnter: authGuard
    },

    {
      path: '/rpm-dashboard/order-form/:client_name/:ehr_data',
      name: 'rpm-dashboard-order-form',
      component: () => import('@/views/rpm/order-form/OrderForm.vue'),
      props: true,
      // beforeEnter: function authGuard(to, from, next) {
      //   const clientId = to.params.client_id;
      //   if (clientId) {
      //     next();
      //   } else {
      //     next(false);
      //   }
      // }
    },
  ],
});

export default router;
